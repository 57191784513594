html, body {
    margin: 0;
    font-family: sans-serif;
    user-select: none;
}

.sizer {
    aspect-ratio: 700/1024;
    width: 700px;
    max-width: 100%;
    margin: auto;
    position: relative;

    background-image: url(img/background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    container-type: size;
}

.board {
    position: absolute;
    width: 100%; 
    height: 100%;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(8, 1fr);
    padding: 10% 6% 28% 5%;
   
}

.details {
    position: absolute;
    width: 90%;
    left: 5%;
    height: 17%;
    bottom: 0;
    color: beige;
    font-size: 3cqw;
}

.row0 {
    grid-row: 1;
}
.row1 {
    grid-row: 2;
}
.row2 {
    grid-row: 3;
}
.row3 {
    grid-row: 4;
}
.row4 {
    grid-row: 5;
}
.row5 {
    grid-row: 6;
}
.row6 {
    grid-row: 7;
}
.row7 {
    grid-row: 8;
}

.col0 {
    grid-column: 1;
}
.col1 {
    grid-column: 2;
}
.col2 {
    grid-column: 3;
}
.col3 {
    grid-column: 4;
}
.col4 {
    grid-column: 5;
}

.tile  {
    position: relative;
    width: 100%;
    height: 100%;
}

.entity, .slate, .no {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

.no {
    position: relative;
}

.reveal .slate {
    opacity: 0.6;
}

.danger .slate {
    opacity: 0.6;
}
.danger .entity {
    filter: grayscale(100%);
    opacity: 0.5;
}

.slate {
    background-image: url(img/slate.png);
}

.available .slate {
    background-image: url(img/slate_light.png);
}

.entrance .entity, .entities .entrance {
    background-image: url(img/entrance.png);
}

.exit .entity, .entities .exit {
    background-image: url(img/exit.png);
}

.monster .entity, .entities .monster {
    background-image: url(img/monster.png);
}

.elite .entity, .entities .elite {
    background-image: url(img/elite.png);
}

.boss .entity, .entities .boss {
    background-image: url(img/boss.png);
}

.boulder .entity, .entities .boulder {
    background-image: url(img/boulder.png);
}

.map .entity, .entities .map {
    background-image: url(img/map.png);
}

.globe .entity {
    background-image: url(img/globe.png);
}

.feather .entity, .entities .feather {
    background-image: url(img/feather.png);
}

.book .entity {
    background-image: url(img/book.png);
}

.hammer .entity {
    background-image: url(img/hammer.png);
}

.fade .entity {
    filter: grayscale(100%);
    opacity: 0.3;
}

.no, .entities .empty {
    background-image: url(img/no.png);
}

.hp { 
    position: absolute;
    left: 10%;
    width: 80%;
    bottom: 10%;
    height: 20%;
    background-color: black;
    border-radius: 10px;
    container-type: size;
}

.hpBar {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: linear-gradient(orange, red);
}

.hpText {
    position: absolute;
    white-space: nowrap;
    width: 100%;
    top: -14%;
    color: white;
    font-size: 20cqw;
    font-family: Impact, fantasy;
    text-align: center;
    user-select: none;
}

.powerupsAndLabel {
    margin-top: 3%;
    width: 100%;
    height: 34%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 75% 25%;
}

.powerups {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-columns: 20% repeat(5, 15%);
    grid-template-rows: 1fr;
    background-color: rgba(0,0,0,0.3);
    border-radius: 5px;
}

.powerupText {
    grid-row: 1;
    grid-column: 1;
    padding-left: 25%;
    padding-top: 10%;
    font-size: 2.5cqw;
}

.powerup {
    grid-row: 1;
    
    width: 80%;
    height: 80%;
    box-sizing: border-box;
    margin-top: 10%;

    background-color:black;
    border-radius: 2px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    filter: grayscale(100%);
}

.stagelabel {
    grid-row: 1;
    grid-column: 2;
    font-size: 3.5cqw;
    background-color: rgba(0,0,0,0.3);
    border-radius: 5px;
    margin-left:10%;
    padding-top:10%;
    padding-left:10%;
}

.powerup.owned {
    filter: none;
}

.powerup.feather {
    grid-column: 2;
    background-image: url(img/feather.png);
}

.powerup.book {
    grid-column: 3;
    background-image: url(img/book.png);
}

.powerup.globe {
    grid-column: 4;
    background-image: url(img/globe.png);
}

.powerup.hammer {
    grid-column: 5;
    background-image: url(img/hammer.png);
}

.powerup.map {
    grid-column: 6;
    background-image: url(img/map.png);
}

.cheats {
    position: absolute;
    width: 100%;
    padding: 10px;
    color: white;
}

.movepoints {
    margin-top: 1%;
}
.attackpoints { 
    margin-top: 2%;
}

.welcome, .selectPuzzle, .selectStage, .solver {
    margin-top: 5%;
}

.welcome .line1 {
    margin-bottom: 2%;
    color: white;
    font-weight: bold;
}

.selectPuzzle .line1, .selectStage .line1, .solver .line1 {
    margin-bottom: 2%;
    color: white;
    font-style: italic;
}

.maplink { 
    color: white;
}

.details .hidden { 
    display: none;
}

.entities {
    width: 100%;
    height: 40%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(9, 1fr);
}

.entities > div {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    filter: grayscale(100%);
}

.entities .selected {
    filter: none;
}

.entities .monster {
   grid-row: 1;
   grid-column: 1;
}
.entities .elite {
   grid-row: 1;
   grid-column: 2;
}
.entities .boss {
   grid-row: 1;
   grid-column: 3;
}
.entities .boulder {
    grid-row: 1;
    grid-column: 4;
 }
 .entities .entrance {
   grid-row: 1;
   grid-column: 5;
}
.entities .exit {
   grid-row: 1;
   grid-column: 6;
}
.entities .map {
   grid-row: 1;
   grid-column: 7;
}
.entities .feather {
   grid-row: 1;
   grid-column: 8;
}
.entities .empty {
    grid-row: 1;
    grid-column: 9;
}
.configureMob {
    margin-top: 2%;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
}

.defeat.hidden, .victory.hidden { 
    display: none; 
}

.overlay.hidden {
    display: none;
}

.defeat, .victory {
    position: absolute;
    top: 25%;
    left: 10%;
    width: 80%;
    color: white;
    font-family: Impact, fantasy;
    font-size: 5cqw;
    background-image: linear-gradient(rgb(40, 40, 40), rgb(0,0,0));
    border-radius: 15px;
}

.defeat {
    height: 52%;
}

.victory {
    top: 25%;
    height: 40%;
}

.defeat .image {
    background-image: url(img/defeat.png);
    margin: auto;
    width: 70%;
    height: 35%;
    background-size: contain;
    background-repeat: no-repeat;
}
.victory .image {
    background-image: url(img/victory.png);
    margin: auto;
    width: 80%;
    height: 40%;
    background-size: contain;
    background-repeat: no-repeat;
}

.defeat .buttons {
    margin: auto;
    margin-top: 5%;
    width: 70%;
    height: 30%;
}
.victory .buttons {
    margin: auto;
    margin-top: 5%;
    width: 30%;
    height: 30%;
}

.victory .attacks, .victory .moves, .defeat .attacks, .defeat .moves {
    text-align: center;
}

.defeat .buttons {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 10%;
    grid-template-rows: 1fr;
}

.defeat .buttons .back {
    grid-row: 1;
    grid-column: 1;
}

.defeat .buttons .retry {
    grid-row: 1;
    grid-column: 2;
}

.result {
    font-family: Arial Narrow, sans-serif;
    margin-right: 1%;
}

.result::after {
    content: "\2713";
    color: green;
}
.result.fail::after {
    content: "x";
    color: red;
}

.rawMaps canvas {
    margin: 10px;
}

.solver {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 100%;
    height: 60%;
}

.solver .text {
    grid-row: 1;
    grid-column: 1;
}
.solver .stage {
    grid-row: 1;
    grid-column: 2;
    font-size: 3.5cqw;
    background-color: rgba(0,0,0,0.3);
    border-radius: 5px;
    margin-left:10%;
    padding-top:10%;
    padding-left:10%;
    height: 75%;
    white-space: nowrap;
}
.solver .link {
    color: white;
    cursor: pointer;
}